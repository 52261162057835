import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';
import FeaturesBlocks from './partials/FeaturesBlocks'
import PrivacyNotice from './partials/PrivacyNotice'
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';

function App() {

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path='/instagram' >
  {() => { 
       window.location.href = 'https://www.instagram.com/bahatitech/'; 
     return null;
  }}
</Route>
        <Route path='/twitter' >
  {() => { 
       window.location.href = 'https://twitter.com/bahati_tech/'; 
     return null;
  }}
</Route>
        <Route path='/facebook' >
  {() => { 
          window.location.href = 'https://www.facebook.com/bahatitech17/'; 
       return null;
  }}
</Route>
        <Route path='/linkedin' >
  {() => { 
          window.location.href = 'https://www.linkedin.com/company/bahati-tech/'; 
       return null;
  }}
</Route>
        <Route path='/bahati' >
  {() => { 
          window.location.href = 'https://www.bahatitech.com/'; 
       return null;
  }}
</Route>
        <Route path="/terms">
          <FeaturesBlocks />
        </Route>
        <Route path="/privacy">
          <PrivacyNotice />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
      </Switch>
    </>
  );
}

export default App;
