import React,{useState} from 'react';
import { Link} from 'react-router-dom';
import { useForm } from "react-hook-form";
import {Label, Input, FormGroup,Button} from 'reactstrap';
import {Form} from 'react-bootstrap';
import {sendEmail} from '../utils/AwsFunctions'
import * as queries from '../graphql/queries.js'
import {PopUp} from '../utils/PopUp'
import {Amplify, API, Storage, Auth, graphqlOperation} from 'aws-amplify'
const awsConfig = require('../aws-exports').default;

Amplify.register(API)
Amplify.register(Storage)
Amplify.register(Auth)
Amplify.configure(awsConfig)
// import Header from '../partials/Header';

export const registerOptions = {
    name: { required: "First name is required" },
    email: { required: "Email is required" }
};
function SignUp(props) {
  const { className} = props; 
  const msg = "Thank you for signing up, now you will get updates about infohorus";
  const [modalPass, setModalPass] = useState(false);
  const togglePass = () => setModalPass(!modalPass);
  const { register, handleSubmit, reset, errors } = useForm();

  async function getCreds(){
    const cred  = await API.graphql(graphqlOperation(queries.getCred, { id: 'ak100' })).catch(err=>{
      console.log("This is err",err)
    });
    console.log("The creds", cred)
    return cred;
    }
    const handleSendEmail = async(data)=>{
  console.log(getCreds())

      console.log("This is the form data", data);
      }
      
      
      const handleError = () => { console.log("Form Errors: "+ errors)};
      const handleRegistration = async (data) =>{ 
        try{
          console.log("Sending to the API")
          handleSendEmail(data)
          reset({name:"", email:"", country:"",company:"", job_title:"",phone:""});
      console.log("This is the users data:"+JSON.stringify(data))
      console.log("Data sent to the API")
      }
      catch(err){
        console.log("API err:", err );
      }
    };


  return (
   <> 
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      {/* <Header /> */}

      {/*  Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h2 className="h2"> Dark Data Discovery - Converting Dark Data into Smart Data</h2>
                <h3>Complete the form below and a representative will be in touch to discuss your requirements.</h3>

              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                  <FormGroup className="flex flex-wrap -mx-3 mb-4" >
                    <div className="w-full px-3">
                      <Label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="name">Name <span className="text-red-600">*</span></Label>
                      <Input name="name" type="text" className="form-input w-full text-gray-800" placeholder="Enter your name" {...register('name', { required: true })}  />
                    </div>
                  </FormGroup>
                  <FormGroup className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <Label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></Label>
                      <Input name="email" type="email" className="form-input w-full text-gray-800" placeholder="Enter your email address" {...register('email', { required: true })}/>
                    </div>
                  </FormGroup>
                  <FormGroup className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <Label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="company">Company <span className="text-red-600">*</span></Label>
                      <Input name="company" type="text" className="form-input w-full text-gray-800" placeholder="Enter your Company" {...register('company', { required: true })}/>
                    </div>
                  </FormGroup>
                  <FormGroup className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <Label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="job_title">Job Title<span className="text-red-600">*</span></Label>
                      <Input name="job_title" type="text" className="form-input w-full text-gray-800" placeholder="Enter your Job Title" {...register('job_title', { required: true })}/>
                    </div>
                  </FormGroup>
                  <FormGroup className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <Label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="phone">Phone<span className="text-red-600">*</span></Label>
                      <Input name="phone" type="text" className="form-input w-full text-gray-800" placeholder="Enter your Phone Number" {...register('phone', { required: true })}/>
                    </div>
                  </FormGroup>
                  <FormGroup className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <Button type="submit" onClick={togglePass} className="btn text-white bg-blue-600 hover:bg-blue-700 w-full">Sign up</Button>
                    </div>
                  </FormGroup>
                  <div className="text-sm text-gray-500 text-center mt-3">
                    By Signing up, you agree to the <Link className="underline" to="/terms">terms & conditions</Link>, and our <Link className="underline" to="/privacy">privacy policy</Link>.
                                </div>
                </Form>
                <div className="flex items-center my-6">
                  <div className="border-t border-gray-300 flex-grow mr-3" aria-hidden="true"></div>
                  <div className="text-gray-600 italic"></div>
                  <div className="border-t border-gray-300 flex-grow ml-3" aria-hidden="true"></div>
                </div>
                <form>
                  <div className="flex flex-wrap -mx-3">
                    <div className="w-full px-3">
                      <Link to="/"><button className="btn px-0 text-white bg-gray-700 hover:bg-gray-800 w-full relative flex items-center">
                        <span className="flex-auto pl-16 pr-8 -ml-16">Back</span>
                      </button></Link>
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </section>

      </main>

    </div>
    <PopUp isOpen={modalPass} 
         btnTxtPositive="Okay"
          btnPositiveLink ="/"  
         body={msg} 
         toggle={togglePass} 
         className = {className}
         />
</>  
  );
}

export default SignUp;