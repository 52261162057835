/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCred = /* GraphQL */ `
  query GetCred($id: ID!) {
    getCred(id: $id) {
      id
      acc
      sec
      createdAt
      updatedAt
    }
  }
`;
export const listCreds = /* GraphQL */ `
  query ListCreds(
    $filter: ModelCredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        acc
        sec
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      job_title
      company
      phone
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        job_title
        company
        phone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
