import React,{useState} from 'react';
import { Link} from 'react-router-dom';
import { useForm,Controller } from "react-hook-form";
import {Label, Input, FormGroup} from 'reactstrap';
import { ErrorMessage } from "@hookform/error-message";
import {Form} from 'react-bootstrap';
import {sendEmail} from '../utils/AwsFunctions'
import * as queries from '../graphql/queries.js'
import {PopUp} from '../utils/PopUp'
import {Amplify, API, Storage, Auth, graphqlOperation} from 'aws-amplify'
const awsConfig = require('../aws-exports').default;

Amplify.register(API)
Amplify.register(Storage)
Amplify.register(Auth)
Amplify.configure(awsConfig)
// import Header from '../partials/Header';

export const registerOptions = {
    name: { required: "First name is required" },
    email: { required: "Email is required" }
};
function Home(props) {
  const { className} = props; 
  const [msg,setMsg] = useState("");
  const [modalPass, setModalPass] = useState(false);
  const togglePass = () => setModalPass(!modalPass);
  const { register, handleSubmit, reset, formState: { errors }, control } = useForm();

  async function getCreds(){
    const cred  = await API.graphql(graphqlOperation(queries.getCred, { id: 'ak100' })).catch(err=>{
      console.log("This is err",err)
    });
    console.log("The creds", cred)
    return cred;
    }
    const handleSendEmail = async(data)=>{
  console.log(getCreds())

      console.log("This is the form data", data);
      }
      
      
      const handleError = (err) => { console.log("Form Errors: ",err);
      setMsg("Please fill in all the required fields");
    togglePass() 
    };
      const handleRegistration = async (data) =>{ 
        try{
          console.log("Sending to the API")
          handleSendEmail(data)
          setMsg("Thank you for signing up, now you will get updates about Daitur");
          reset({name:"", email:"", country:"",company:"", job_title:"",phone:""});
      console.log("This is the users data:"+JSON.stringify(data))
      console.log("Data sent to the API")
      togglePass()
      }
      catch(err){
        console.log("API err:", err );
      }
    };


  return (
   <> 
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      {/* <Header /> */}

      {/*  Page content */}
      <main className="flex-grow">
        <section className="">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className=" ">
              {/* Form */}
              <div className="max-w-sm mx-auto">
                <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                
    {/* <Controller  type="text" control={control} name="organisation"   {...register("organisation" )} render={({ field }) => (<Input className=" w-full inline-block px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50" 
          placeholder="Company" 
          {...field} />
        )} rules={{ required: "Please fill in your company's name"}}  />
    <ErrorMessage errors={errors} className="err mb-4" name="organisation" as="p" />
    
    <Controller  type="text" control={control} name="jobtitle"   {...register("jobtitle" )} render={({ field }) => (<Input className=" w-full inline-block px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50" 
          placeholder="Job Title" 
          {...field} />
        )} rules={{ required: "Please fill in your job title"}}  />
    <ErrorMessage errors={errors} className="err mb-4" name="jobtitle" as="p" />
    
    <Controller  type="email" control={control} name="email"   {...register("email" )} render={({ field }) => (<Input className=" w-full inline-block px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50" 
          placeholder="Email" 
          {...field} />
        )} rules={{ required: "Please fill in your email address",validate: () => {
          if(getValues("email").includes("@gmail")|| getValues("email").includes("@yahoo")){
              return "Please enter your company email, not your personal email.";
            }
            }}}  />
    <ErrorMessage errors={errors} className="err mb-4" name="email" as="p" />
    
    <Controller  type="text" control={control} name="phone"   {...register("phone" )} render={({ field }) => (<PhoneInput className=" w-full inline-block px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50" 
          placeholder="Phone number" 
          {...field} />
        )} rules={{ required: "Please fill in your phone number"}}  />
    <ErrorMessage errors={errors} className="err mb-4" name="phone" as="p" />
                  */}
                  <FormGroup className="flex flex-wrap -mx-3 mb-4" >
                    <div className="w-full px-3">
                      <Label className="block text-white text-sm font-medium mb-1" htmlFor="name">Name <span className="text-red-600">*</span></Label>     
                      <Controller  type="text" control={control} name="name"   {...register("name" )} render={({ field }) => (
          <Input className="form-input w-full text-white" placeholder="Enter your name" 
          {...field} />
        )} rules={{ required: "Please fill in your name"}}  />
    <ErrorMessage errors={errors} className="err mb-4" name="fname" as="p" />
    
                    </div>
                  </FormGroup>
                  <FormGroup className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <Label className="block text-white text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></Label>
                      <Input name="email" type="email" className="form-input w-full text-white" placeholder="Enter your email address" {...register('email', { required: true })}/>
                    </div>
                  </FormGroup>
                  <FormGroup className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <Label className="block text-white text-sm font-medium mb-1" htmlFor="company">Company <span className="text-red-600">*</span></Label>
                      <Input name="company" type="text" className="form-input w-full text-white" placeholder="Enter your Company" {...register('company', { required: true })}/>
                    </div>
                  </FormGroup>
                  <FormGroup className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <Label className="block text-white text-sm font-medium mb-1" htmlFor="job_title">Job Title<span className="text-red-600">*</span></Label>
                      <Input name="job_title" type="text" className="form-input w-full text-white" placeholder="Enter your Job Title" {...register('job_title', { required: true })}/>
                    </div>
                  </FormGroup>
                  <FormGroup className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <Label className="block text-white text-sm font-medium mb-1" htmlFor="phone">Phone<span className="text-red-600">*</span></Label>
                      <Input name="phone" type="text" className="form-input w-full text-white" placeholder="Enter your Phone Number" {...register('phone', { required: true })}/>
                    </div>
                  </FormGroup>
                  <FormGroup className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button type="submit" className="bg-gradient-to-r from-purple-800 to-green-500 hover:from-pink-500 hover:to-green-500 text-white font-bold py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out w-full">Complete</button>
                    </div>
                  </FormGroup>
                  <div className="text-sm text-gray-500 text-center mt-3">
                    By Signing up, you agree to the <Link className="underline" target="blank" to="/terms">terms & conditions</Link>, and our <Link className="underline" target="blank" to="/privacy">privacy policy</Link>.
                                </div>
                </Form>
               
              </div>

            </div>
          </div>
        </section>

      </main>

    </div>
    <PopUp isOpen={modalPass} 
         btnTxtPositive="Okay"
          btnPositiveLink ="/"  
         body={msg} 
         toggle={togglePass} 
         className = "bg-gradient-to-r from-purple-800 to-green-500 hover:from-pink-500 hover:to-green-500 text-white font-bold py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out"
         />
</>  
  );
}

export default Home;